export default {
  download: {
    install: "安装APP",
    goH5: "暂不下载,体验手机网页版",
    tutorial: "下滑查看安装教程",
    iosTitle: "iOS企业级应用添加信任",
    subTitle: "安装APP后请按以下步骤操作，否则无法正常使用",
    openTutorial: "查看安装教程",
    gotIt: "知道了",
    customerService: "在线客服",
    saySomething: "说点什么",
  },
  notify: {
    timeout: "网路请求超时",
  },
};
