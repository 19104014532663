export default {
  download: {
    install: "Haz clic para instalar",
    goH5: "No descargar. Utiliza la versión web móvil",
    tutorial: "Deslizar para ver el tutorial de instalación",
    iosTitle: "Añadir confianza a aplicaciones empresariales iOS",
    subTitle:
      "Después de instalar el APP, sigue los pasos siguientes, de lo contrario, no funcionará correctamente",
    openTutorial: "Ver la guía de instalación",
    gotIt: "Entendido",
    customerService: "Servicio al cliente en línea",
    saySomething: "Diga algo",
  },
  notify: {
    timeout: "Tiempo de solicitud de red agotado",
  },
};
