import { createI18n } from "vue-i18n";
import zh from "./zh";
import en from "./en";
import vi from "./vi";
import th from "./th";
import ja from "./ja";
import pt from "./pt";
import sp from "./sp";

const userLang = navigator.language.toLowerCase();
let langCode = "zh";

langCode = process.env.VUE_APP_CITY === "ph" ? "en" : "zh";

if (!process.env.VUE_APP_CITY) {
  switch (true) {
    case userLang.indexOf("vi") > -1:
      langCode = "vi";
      break;
    case userLang.indexOf("en") > -1:
      langCode = "en";
      break;
    case userLang.indexOf("zh") > -1:
      langCode = "zh";
      break;
    case userLang.indexOf("th") > -1:
      langCode = "th";
      break;
    case userLang.indexOf("ja") > -1:
      langCode = "ja";
      break;
    case userLang === "pt" || userLang.startsWith("pt-"):
      langCode = "pt";
      break;
    case userLang === "es" || userLang.startsWith("es-"):
      langCode = "sp";
      break;
    default:
      langCode = "en";
  }
}

const i18n = createI18n({
  // 如果本地有语言标识就采用本地，没有就根据浏览器语言默认标识显示语言
  locale: langCode,
  legacy: false,
  messages: {
    zh,
    en,
    vi,
    th,
    ja,
    pt,
    sp,
  },
});
export default i18n;
