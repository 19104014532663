// @ts-nocheck

export function google(id: string) {
  var hm = document.createElement("script");
  hm.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", id);
}
