import common from "./common";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let serviceName = "";
let service: typeof common | null = null;

export function setService(_serviceName: string) {
  serviceName = _serviceName;
}

function _getService() {
  if (service) {
    return service;
  }
  service = Object.assign({}, common);
  return service;
}

export function getService() {
  const service = _getService();
  return service;
}
