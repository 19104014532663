import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./lang";
import VueClipboard from "vue-clipboard2";
import Vant from "vant";
import "vant/lib/index.css";

import "@/style/layout.less";
import "@/plugins";
import "@/service";

createApp(App).use(router).use(i18n).use(VueClipboard).use(Vant).mount("#app");
