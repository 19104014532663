import _ from "lodash";

export function seoDoamin() {
  const plat = _.split(process.env.VUE_APP_SCOPE, "-");
  const pid = _.get(plat, 0) || "kk";
  let domain = "https://admin.mlseeou.com";
  if (pid === "zz" || pid === "bty") {
    domain = "https://api.btyseo.com";
  } else if (pid === "kk") {
    domain = "https://admin.kkseodomain.com";
  }

  return domain;
}
