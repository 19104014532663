export default {
  download: {
    install: "アプリをインストール",
    goH5: "まだダウンロードしません。携帯サイトのバージョンを体験します。",
    tutorial: "下にスワイプしてインストールガイダンスを確認します。",
    iosTitle: "iOS 法人企業アプリケーションの信頼を追加します。",
    subTitle:
      "アプリをインストールした後に、下記のステップで操作してください。そうしないと、正常的に使用できません。",
    openTutorial: "インストールガイダンスを確認します",
    gotIt: "分かりました",
    customerService: "ヘルプデスク",
    saySomething: "何かお話ししてください",
  },
  notify: {
    timeout: "ネットワークリクエストがタイムアウトしました",
  },
};
