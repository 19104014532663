
import { defineComponent } from "vue";
import { util } from "dev-sdk";
import { getService } from "@/service";

export default defineComponent({
  data() {
    return {
      isLoading: true,
      android: "",
      ios: "",
      h5: "",
      showModal: false,
    };
  },

  async created() {
    await this.getDownload();
  },

  methods: {
    async getDownload() {
      const data = await getService().global.downloadUrl();
      if (!data) {
        return;
      }
      this.isLoading = false;
      this.android = data.android;
      this.ios = data.ios;
      this.h5 = data.h5;
    },
    goH5() {
      window.open(this.h5, "_blank");
    },
    downloadApp() {
      if (util.OS.iPhone) {
        if (this.ios.indexOf("plist") > -1) {
          const link = `itms-services://?action=download-manifest&url=${this.ios}`;
          window.location.href = link;
        } else {
          window.location.href = this.ios;
        }
        return;
      }
      window.location.href = this.android;
    },
  },

  render() {
    return (
      <div class="ml-seo">
        {this.isLoading ? (
          <div class="cover">
            <img class="loading" src={require("./assets/loading.gif")} />
          </div>
        ) : null}
        <div class="top"></div>
        <div class="img-content">
          <img class="bg" src={require(`./assets/bg.jpg`)} />
        </div>

        <div class="footer fresh">
          <div class="home-btn btn" onClick={this.downloadApp}>
            安装APP
          </div>
          <a class="go-h5" onClick={this.goH5}>
            暂不安装，体验手机网页版{">"}
            {">"}
          </a>
        </div>
      </div>
    );
  },
});
