/* eslint no-var: off */
import "amfe-flexible";

// // var clientWidth = window.screen.width;
var dpr = 1;
var vp = document.createElement("meta");
vp.name = "viewport";
vp.content = `initial-scale=${(1.0 * 1) / dpr}, maximum-scale=${
  (1.0 * 1) / dpr
}, minimum-scale=${
  (1.0 * 1) / dpr
}, user-scalable=no, width=device-width, viewport-fit=cover`;
var firstMeta = document.getElementsByTagName("meta")[0];
firstMeta.parentNode!.insertBefore(vp, firstMeta);
