export default {
  download: {
    install: "ติดตั้งเดียวนี้",
    goH5: "ยังไม่ติดตั้ง สัมผัสประสบการณ์เวอร์ชันเบราว์เซอร์มือถือ",
    tutorial: "เลื่อนลงเพื่อดูวิธีการติดตั้ง",
    iosTitle: "เพิ่มความเชื่อถือบนแอปพลิเคชัน IOS",
    subTitle:
      "หลังจากติดตั้งแอปแล้ว โปรดทำตามขั้นตอนด้านล่าง มิฉะนั้น แอปจะไม่ทำงาน",
    openTutorial: "ตรวจดูวิธีการติดตั้ง",
    gotIt: "เข้าใจแล้ว!",
    customerService: "แชทสด",
    saySomething: "พูดอะไรหน่อย",
  },
  notify: {
    timeout: "คำขอเครือข่ายหมดเวลา",
  },
};
