export default {
  download: {
    install: "Clique para instalar",
    goH5: "Não faça download. Use a versão da Web móvel",
    tutorial: "Deslize para ver o tutorial de instalação",
    iosTitle: "Adicionar confiança em aplicativos empresariais iOS",
    subTitle:
      "Após instalar o APP, siga os passos abaixo, caso contrário, não funcionará corretamente",
    openTutorial: "Veja o guia de instalação",
    gotIt: "Entendi",
    customerService: "Atendimento ao cliente online",
    saySomething: "Diga algo",
  },
  notify: {
    timeout: "Tempo de solicitação de rede esgotado",
  },
};
