import { AxiosRequestConfig, AxiosError } from "axios";
import qs from "qs";
import { http } from "dev-sdk/lib/http";

http.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    if (config.method === "post") {
      const params = config.data;
      if (typeof params === "object") {
        if (
          config.headers &&
          (config.headers["Content-Type"] === "multipart/form-data" ||
            config.headers["Content-Type"] === "application/json")
        ) {
          config.data = params;
        } else {
          config.data = qs.stringify(params, { allowDots: true });
        }
      }
    }
    config.headers = Object.assign(
      {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      config.headers
    );
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: AxiosError<any>) {
    return Promise.reject(error);
  }
);

export default http;
